<!--  中间件-->
<template>
  <!-- 
      layout.sync 位置坐标，数据源。值必须为 Array
      row-height  每行的高度，单位像素
      maxRows     定义最大行数
      margin      值必须是包含两个 Number的数组，数组中第一个元素表示水平边距，第二个表示垂直边距，单位为像素
      isResizable 标识栅格中的元素是否可调整大小
      verticalCompact 标识布局是否垂直压缩
  -->
  <grid-layout
    :layout.sync="layout"
    :col-num="12"
    :row-height="80"
    :is-draggable="isEditStatus"
    :is-resizable="isEditStatus"
    :is-mirrored="false"
    :vertical-compact="true"
    :margin="[10, 10]"
    :use-css-transforms="true"
    :max-rows="maxRows"
  >
    <!-- 背景的方格子布局循环事件
        非编辑 状态不显示格子
    -->
    <div class="layout-test" v-if="isEditStatus">
      <div
        class="layout-child"
        v-for="(item, index) in squareGridNum"
        :key="index"
      ></div>
    </div>
    <!-- 
      i 栅格中元素的ID
      x 标识栅格元素位于第几列，需为自然数
      y 标识栅格元素位于第几行，需为自然数
      w 标识栅格元素的初始宽度，值为colWidth的倍数
      h 标识栅格元素的初始高度，值为rowHeight的倍数
    -->
    <grid-item
      v-for="(item, index) in layout"
      :key="item.i"
      :x="Number(item.x)"
      :y="Number(item.y)"
      :w="Number(item.w)"
      :h="Number(item.h)"
      :i="item.i"
      :min-w="Number(item.minW)"
      :min-h="Number(item.minH)"
      @moved="movedEvent"
      @resize="resizedEvent"
      @layoutUpdated="layoutUpdatedEvent"
    >
      <!-- {{item.i}} -->
      <!-- 季度业绩 -->
      <Achievement-card
        :AchievementCardData="item"
        v-if="item.type === 'Achievement'"
        :homePageObject="homePageObject"
      />
      <!-- 审批中心 -->
      <Audit-card
        :tableData="item"
        v-if="item.type === 'Audit'"
        :homePageObject="homePageObject"
      />
      <!-- 工作量排行榜 -->
      <Workload-ranking
        v-if="item.type === 'WorkloadRanking'"
        :tableData="item"
        :homePageObject="homePageObject"
      />
      <!-- 项目甘特图 -->
      <Project-gantt
        v-if="item.type === 'ProjectGantt'"
        :tableData="item"
        :homePageObject="homePageObject"
      />
      <!-- 最近记录 -->
      <Record-card
        v-if="item.type === 'Record'"
        :RecordData="item"
        :homePageObject="homePageObject"
      />
      <!-- 简报 -->
      <Brief-report
        :homePageObject="homePageObject"
        v-if="item.type === 'Brief'"
        :BriefData="item"
      />
      <!-- 销售数据 -->
      <Sales-data
        v-if="item.type === 'SalesData'"
        :homePageObject="homePageObject"
        :SalesDatas="item"
        :currentResizedLayout="currentResizedLayout"
      />
      <!-- 合同排行榜 -->
      <Contract-list v-if="item.type === 'Contract'" :ContractData="item" />
      <!-- 汇款排行榜 -->
      <Remittance-list
        v-if="item.type === 'Remittance'"
        :RemittanceData="item"
      />
      <!-- 卡片视图 -->
      <Card-view
        :homePageObject="homePageObject"
        v-if="item.type === 'CardView'"
        :CardViewData="item"
      />
      <!-- :objectApi="objectApi" -->
      <!-- 我的任务 -->
      <My-task
        v-if="item.type === 'Mytask'"
        :MyTaskData="item"
        :homePageObject="homePageObject"
      />
      <!-- 图表组件卡片：主页中直接添加图表类型为homepageChart -->
      <Dashboard-card
        v-if="item.type === 'Dashboard' || item.type === 'homepageChart'"
        :DashboardData="
          item.layout && item.layout.length ? item.layout[0] : item
        "
        :assemblySubscript="index"
        :cpnObject="item"
        :commonDataArr="commonDataArr"
        :isEdit="isEdit"
        :isEditStatus="isEditStatus"
        :useLocation="useLocation"
        @fnchooseLinkage="fnchooseLinkage"
        @fnchoosepierce="fnchoosepierce"
        @fnchoosedelete="fnchoosedelete"
        @fnchooseEdit="fnchooseEdit"
        @addComponToKuOpen="addComponToKuOpen"
        @linkagegetData="linkagegetData"
        :homePageObject="homePageObject"
        :currentResizedLayout="currentResizedLayout"
        ref="DashboardCard"
      />
      <!-- 仪表板页面卡片 -->
      <Personal-page-cpn-card
        v-if="item.type === 'PersonalPageCpn'"
        :PersonalPageCpnData="item"
        :homePageObject="homePageObject"
        :useLocation="useLocation"
        @linkagegetData="linkagegetData"
      />
      <!-- 嵌入页面 -->
      <Embedded-page
        v-if="item.type === 'Embeddedpage'"
        :EmbeddedPageData="item"
      />
      <!-- 公告 -->
      <Notice
        v-if="item.type === 'Bulletin'"
        :homePageObject="homePageObject"
        :NoticeData="item"
      />
      <!-- 同事圈 -->
      <Tsquan
        v-if="item.type === 'tsquan'"
        :tableData="item"
        :homePageObject="homePageObject"
      />
      <!-- 客服个案统计图 -->
      <CustomerServiceStatistics
       v-if="item.type === 'CustomerService'"
       :homePageObject="homePageObject"
       :CustomerServiceData="item"
      />
          <!-- 渠道解决时间 -->
      <ChannelResolutionTime
        v-if="item.type === 'Channel'"
        :homePageObject="homePageObject"
        :ChannelData="item"
      />
      <!-- 满意度评价 -->
      <SatisfactionEvaluation
        v-if="item.type === 'Satisfaction'"
        :homePageObject="homePageObject"
        :SatisfactionData="item"
      />
      <!-- 自定义组件 -->
      <Preview
        v-if="item.type === 'Preview'"
        :homePageObject="homePageObject"
        :PreviewData="item"
        :api="item.name.split('&myPage&')[1]"
      />
    </grid-item>
  </grid-layout>
</template>

<script>
// 引入组件
import * as Cpn from "./index";
import Preview from "@/views/injectionComponent/components/Preview.vue";
// 主页拖拽插件
import VueGridLayout from "vue-grid-layout";
export default {
  props: {
    // 所有组件的数据集合数组
    commonDataArr: {
      type: Array,
      default: () => [],
    },
    //仪表板所在文件夹的编辑权限
    isEdit: {
      type: Boolean,
      default: false,
    },
    /**
     *  是否为编辑状态
     * 非编辑状态不可拖动
     */
    isEditStatus: {
      type: Boolean,
      default: false,
    },
    // 当前使用子组件的页面的属性对象（包含id，type，name等）
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
    useLocation: {
      // 图表使用位置：报表、仪表板、图表展示全屏
      type: String,
      default: "",
    },
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Preview,
    ...Cpn,
  },
  data() {
    return {
      /**
       * 组件的数据，需要深克隆数据，
       * 可以在 当前组件随意拖动位置，
       *以至于可以最后将移动后的数据发送后端
       */
      layout: [],
      //当前组件缩放后，保存布局给仪表板组件缩放用（测试中，可删）
      currentResizedLayout: {},
    };
  },
  watch: {
    // 发射页面布局
    layout: {
      handler(val) {
        this.$emit("locationLayout", val);
      },
      deep: true,
    },
    // 监听数据变化
    commonDataArr: {
      handler(val) {
        this.layout = val;
      },
      deep: true,
      immediate: true, //第一次就执行
    },
  },
  computed: {
    /**
     * 网格的背景布局计算规则
     * （每行12格*总共数组的长度）
     */
    squareGridNum() {
      return 50 * 12;
    },
    /**
     * 网格的最大行数限制
     * 暂时固定50，因为zoho是 46格
     */
    maxRows() {
      return this.squareGridNum / 12;
    },
  },
  methods: {
    linkagegetData(e) {
      this.$bus.$emit("getLinkagegetData", e); // 此时获取到该图表的所有数据
    },
    // 移动后的事件
    movedEvent() {
      //
    },
    // 调整大小后的事件
    resizedEvent(i, newH, newW, newHPx, newWPx) {
      //
      // 当前组件缩放后，保存布局给仪表板组件缩放用（测试中，可删）
      this.currentResizedLayout = { i, newH, newW, newHPx, newWPx };

      // this.$bus.$emit("q");
    },
    // 更新事件（布局更新或栅格元素的位置重新计算）
    layoutUpdatedEvent() {},
    /**
     * 组件的事件部分
     */
    //联动弹框
    fnchooseLinkage(value, infos) {
      this.$emit("fnchooseLinkage", value, infos);
    },
    // 穿透弹框
    fnchoosepierce(chartInfo, through) {
      this.$emit("fnchoosepierce", chartInfo, through);
    },
    // 删除弹框
    fnchoosedelete(id, name) {
      this.$emit("fnchoosedelete", id, name);
    },
    // 编辑弹框
    fnchooseEdit(info) {
      this.$emit("fnchooseEdit", info);
    },
    addComponToKuOpen() {
      this.$emit("addComponToKuOpen");
    },
    // 卡片视图事件
    // newTaskPopups(data) {
    //   this.$emit("newTaskPopups",data);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/cardStyle.scss";

.item {
  background-color: #ccc;
  height: 100%;
}

.vue-grid-layout {
  margin-left: -10px;
  margin-right: -10px;
}

.vue-grid-item {
  top: -10px;
  overflow: hidden;
}
/* 拖动块的阴影颜色 */
::v-deep .vue-grid-item.vue-grid-placeholder {
  background-color: #b6d5fc !important;
  opacity: 0.9;
}

.layout-test {
  margin-left: 10px;
  .layout-child {
    float: left;
    width: calc((100% - 120px) / 12);
    box-sizing: border-box;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #e4f0ff;
    z-index: -1;
  }
}
</style>