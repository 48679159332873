<!-- /* 主页、仪表板全屏： 关于全屏下主题模式的保存：
1.在全屏下通过切换UI风格来进行保存主题模式；
2.在重新进入全屏时监听已保存的主题模式，给UI风格变量赋值；
3.图表主题模式在非全屏下应该默认为浅色，只有在全屏模式下才需要使用保存的主题模式；
*/ -->
<template>
  <el-dialog
    :visible.sync="homePageFullScreenTo"
    :close-on-click-modal="false"
    :close-on-press-escape="true"
    :before-close="homePageFullScreenClose"
    custom-class="fullScreenDialogClass"
  >
    <div
      class="headerContent"
      slot="title"
      :class="styleValue === 'dark' ? 'blackChange' : ''"
    >
      <!-- 标题 -->
      <div class="header_title">{{ homePagetitle }}</div>
      <!-- 刷新 -->
      <div class="chartRefresh">
        <!-- 定时刷新和手动刷新 -->
        <div class="timingRefresh">
          <!-- 更新频率 -->
          <span>{{ $t("vue_label_dashboard_updatefrequency") }}：</span>
          <el-select
            v-model="timeValue"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
            @change="changeRefreshTiming"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- UI风格切换 -->
        <div class="switchStyle">
          <el-select
            v-model="styleValue"
            @change="changeSwitchStyle"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in optionStyle"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 手动刷新 -->
        <div class="manuallyRefresh" @click="refreshEvent">
          <i class="el-icon-refresh-right" ref="loadimgbox"></i>
          <span>{{ $t("label.refresh") }}</span>
        </div>
      </div>
    </div>
    <div
      class="bodyContent"
      :class="styleValue === 'dark' ? 'blackChangeBody' : ''"
    >
      <!-- 主页内容组件 -->
      <Common-index
        :commonDataArr="commonDataArr"
        useLocation="dashboardFullScreen"
        ref="CommonIndex"
      />
    </div>
  </el-dialog>
</template>

<script>
/**
 * @description 主页、仪表板全屏展示
 * 功能：
 * 1、主页、仪表板全屏展示
 * 2、刷新
 * 逻辑：
 * 1、在全屏下通过切换UI风格来进行保存主题模式；
 * 在重新进入全屏时监听已保存的主题模式，给UI风格变量赋值；
 * 图表主题模式在非全屏下应该默认为浅色，只有在全屏模式下才需要使用保存的主题模式
 */
import CommonIndex from "@/views/homePage/components/CommonIndex.vue"; // 主页(和仪表板)内容组件
import screenfull from "screenfull";

export default {
  components: {
    CommonIndex,
  },
  props: {
    /**
     * 控制是否全屏展示
     */
    homePageFullScreenTo: {
      type: Boolean,
      default: false,
    },
    /**
     * 全屏弹窗标题
     */
    homePagetitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      timer: null, //定时器
      commonDataArr: [], //接收传过来的组件Common-index需要的数据
      options: [
        {
          value: "1",
          label: this.$i18n.t("label.appointment.wizard.subtitle4-1-1"), //15分钟
        },
        {
          value: "2",
          label: this.$i18n.t("label.appointment.wizard.subtitle1-3"), //30分钟
        },
        {
          value: "3",
          label: this.$i18n.t("label.appointment.wizard.subtitle1-4"), //45分钟
        },
        {
          value: "4",
          label: this.$i18n.t("label.appointment.wizard.subtitle4-2-1"), //1小时
        }, // 定时切换频率选项
      ],
      timeValue: "2", // 定时刷新表单数据，设置默认为30分钟
      styleValue: "roma", // UI风格，默认浅色
      optionStyle: [
        {
          value: "roma",
          label: this.$i18n.t("vue_label_homepage_bigscreen_light"), //浅色
        },
        {
          value: "dark",
          label: this.$i18n.t("vue_label_homepage_bigscreen_dark"), //深色
        },
      ], // UI风格切换切换选项
    };
  },
  computed: {
    /**
     * 获取存储的主题颜色
     */
    themeColor() {
      return this.$store.getters.getThemeColor;
    },
  },
  watch: {
    /**
     * 监听所存储的主题颜色，给全屏UI风格变量赋值
     */
    themeColor: {
      handler() {
        this.styleValue = this.themeColor;
      },
      immediate: true,
    },
  },
  mounted() {
    /**
     * 监听窗口大小改变事件,判断浏览器是否处于全屏状态，否的话退出全屏
     */
    window.addEventListener("resize", this.windowResizeFn);
    /**
     * 开始全屏
     */
    this.$bus.$on("letScreenSuccess",this.letScreenSuccessCallback);
    /**
     * 标准主页全屏数据
     */
    this.$bus.$on("screenFullStandardData", this.screenFullDataCallback);
    /**
     * 个人主页全屏数据
     */
    this.$bus.$on("screenFullPersonalData", this.screenFullDataCallback);
    /**
     * 简档主页全屏数据
     */
    this.$bus.$on("screenFullRoleData", this.screenFullDataCallback);
    /**
     * 整个仪表板全屏数据
     */
    this.$bus.$on("screenFullWholeDashboardData", this.screenFullDataCallback);
    /**
     * 组件加载之后，设置定时刷新
     */
    this.changeRefreshTiming(this.timeValue);
  },
  destroyed() {
    this.$bus.$off("letScreenSuccess",this.letScreenSuccessCallback);
    this.$bus.$off("screenFullStandardData",this.screenFullDataCallback);
    this.$bus.$off("screenFullPersonalData",this.screenFullDataCallback);
    this.$bus.$off("screenFullRoleData",this.screenFullDataCallback);
    this.$bus.$off("screenFullWholeDashboardData",this.screenFullDataCallback);
    window.onresize = null;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeFn);
  },
  methods: {
    screenFullDataCallback(e)  {
      this.commonDataArr = e;
    },
    letScreenSuccessCallback ()  {
      if (!screenfull.enabled) {
        this.$message({
          message: this.$i18n.t(
            "vue_homepage_dashboard_not_support_fullscreen"
          ),
          type: "warning",
        });
        return false;
      }
      screenfull.toggle(
        document.getElementsByClassName("fullScreenDialogClass")[0]
      );
      this.$store.commit("setIsFullScreen", true); // 设置了全屏
    },
    /**
     * 监听窗口大小改变事件,判断浏览器是否处于全屏状态，否的话退出全屏
     */
    windowResizeFn() {
      let that = this;
      if (!that.checkFull()) {
        // 退出全屏后要执行的动作
        that.clearBackAll();
        screenfull.exit();
      }
    },
    /**
     * 退出全屏后要执行的动作
     */
    clearBackAll() {
      if (!screenfull.isFullscreen) {
        this.$emit("homePageFullScreenClose");
        this.$store.commit("setIsFullScreen", false); // 取消全屏
      }
    },
    /**
     * 判断浏览器是否处于全屏状态（需要考虑兼容问题）
     */
    checkFull() {
      //火狐浏览器
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    /**
     * ESC关闭弹框
     */
    closeScreenFull() {
      screenfull.exit();
      this.$emit("closeScreenFull");
      // ESC关闭大屏时，终止定时器
      clearInterval(this.timer);
      this.timer = null;
      this.$store.commit("setIsFullScreen", false); // 取消全屏
    },
    /**
     * 关闭全屏弹框
     */
    homePageFullScreenClose() {
      this.$emit("homePageFullScreenClose");
      this.$store.commit("setIsFullScreen", false); // 取消全屏
      screenfull.exit();
      clearInterval(this.timer);
      this.timer = null;
    },
    /**
     * 组件加载之后，设置定时刷新
     * @param {val} 定时刷新频率
     */
    changeRefreshTiming(val) {
      var timeVal = 0;
      if (val == "1") {
        timeVal = 900; //15min
      } else if (val == "2") {
        timeVal = 1800; //30min
      } else if (val == "3") {
        timeVal = 2700; //45min
      } else if (val == "4") {
        timeVal = 3600; //60min
      }
      clearInterval(this.timer);
      this.timer = null; //清空上一个选择的定时器
      this.timer = setInterval(() => {
        this.$bus.$emit("refreshOverallData");
      }, 1000 * timeVal);
    },
    /**
     * 手动刷新
     */
    refreshEvent() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.$bus.$emit("refreshOverallData");
      setTimeout(() => {
        this.$refs.loadimgbox.classList.remove("loadimg");
      }, 1000);
      clearInterval(this.timer);
      this.timer = null;
      // 点击完刷新按钮之后，需要再次设置定时器为30分钟刷新频率
      this.timeValue = "2";
      this.changeRefreshTiming(this.timeValue);
    },
    /**
     * UI风格切换
     */
    changeSwitchStyle(val) {
      this.$store.commit("setThemeColor", val); // 设置主题颜色
    },
  },
};
</script>

<style lang="scss" scoped>
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
::v-deep .el-dialog {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background: #e7eaeb;
  .el-dialog__header {
    // position: fixed;
    // left: 0;
    // top: 0;
    // z-index: 999;
    // width: 100%; //翻页后要删(该行之前)
    border: none;
    padding: 0;
    //弹框'X'号
    .el-dialog__headerbtn {
    }
    //头部其他
    .blackChange {
      background: black !important;
      color: #b9b8ce !important;
    }
    .headerContent {
      color: #475059;
      background: #f0f3f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 100px 16px 30px;
      .header_title {
        font-size: 16px;
      }
      .chartRefresh {
        display: flex;
        // 定时刷新和UI风格切换
        .timingRefresh,
        .switchStyle {
          z-index: 9999;
          margin-right: 30px;
        }
        //手动刷新
        .manuallyRefresh {
          &:hover {
            cursor: pointer;
          }
          line-height: 24px;
          //刷新按钮
          .el-icon-refresh-right {
          }
          span {
            margin-left: 4px;
          }
        }
      }
    }
  }
  .el-dialog__body {
    // margin-top: 56px; //翻页后要删
    padding: 0;
    height: calc(100% - 56px);

    //正常背景(即默认背景为浅色)
    .bodyContent {
      min-height: 100%;
      background: #e7eaeb;
      padding: 16px 36px;
      .el-card {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
.el-select {
  width: 104px;
  height: 24px;
  ::v-deep .el-input {
    height: 24px;
  }
  ::v-deep .el-input__inner {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  ::v-deep .el-select__caret {
    line-height: 24px;
  }
} //背景为黑时样式的改变
.blackChangeBody {
  background: black !important;
  ::v-deep .el-card {
    background: #1a1d28 !important;
    // loading的背景色
    .el-loading-mask {
      background: #1a1d28;
    }
    .el-table th,
    .el-table tr {
      background-color: #1a1d28 !important;
      color: #b9b8ce !important;
    }
    //card头部
    .clearfix {
      color: white !important;
      .refresh-date {
        div {
          font-weight: normal !important;
        }
      }
    }
    //card内容
    .el-card__body {
      color: #b9b8ce !important;
      //主页审批中心
      //表格
      .tabsTable_content {
        .el-tabs__item {
          color: #b9b8ce !important;
        }
        .el-tabs__item.is-active {
          color: #409eff !important;
        }
      }
      //翻页
      .turnPageBox {
        .jump {
          input {
            color: #1a1d28;
          }
        }
      }
      //卡片视图
      .bottom-box {
        border-top: 1px solid #728194 !important;
      }
    }
  }
  //表格图表的样式
  ::v-deep .el-table {
    background: #1a1d28 !important;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #728194;
  }
  ::v-deep .el-table__body tr:hover > td {
    background: #1a1d28 !important;
  }
}
</style>
